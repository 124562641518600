@charset 'utf-8';
@import "import/var";
@import "import/animation";

/***********************************************************************
 ***********************************************************************
 ***********************************************************************
## Layout
*/

.root {
	box-sizing: border-box;
	overflow: hidden;
	position: relative;
	top: 0;
	left: 0;
}

@mixin container($width: $pc_container_width) {
	width: 100%;
	box-sizing: border-box;
	padding-left: $pc_padding;
	padding-right: $pc_padding;
	margin-left: auto;
	margin-right: auto;
	max-width: $width;
}

.pc_container,
.ipc_container,
.pc_tb_container,
.container {
	@include container;
	&._relative {
		position: relative;
		top: 0;
		left: 0;
	}
	&._md {
		@include container(1000px + $pc_padding * 2);
	}
	&._sm {
		@include container(600px);
	}
	&._sm2 {
		@include container(300px);
	}
}

.pc_padding,
.padding {
	padding-left: $pc_padding;
	padding-right: $pc_padding;
}

.main_flex_area {
	position: relative;
	top: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
	> * {
		min-width: 0;
		max-width: 100%;
		width: 100%;
	}
}

.main_column {
	order: 1;
	// width: 960px;
	width: 100%;
	box-sizing: border-box;
	padding-left: 40px;
}

.sub_column {
	order: 2;
	width: 215px;
	flex-basis: 215px;
	flex-shrink: 0;
	flex-grow: 0;
	overflow: hidden;
	box-sizing: content-box;
	transform: translateX(-1px);
	padding-left: 11px;
	padding-right: 11px;
	border-right: 1px solid #b1b5ba;
	padding-top: 45px;
}

.main_content {
	font-size: $pc_default_font_size;
	margin-bottom: 120px;
	//padding-bottom: 80px;
}

.scroll_to_top {
	right: 0;
	// right: 0;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Utility - Cosmetic

ユーティリティー設定。
このサイト専用というわけでもない共通設定。

*/
.pc_none,
.root .pc_none {
	display: none;
}
.block,
.pc_block,
.ipc_block {
	display: block;
}

.xs_block {
	display: none;
}

@media screen and (min-width: 1200px), print {
	.lg_block {
		display: block;
	}
}

@media screen and (min-width: 1000px) and (max-width: 1099px), print {
	.pc_s_block {
		display: block;
	}
}
.tel_link,
.tel_link-no {
	pointer-events: none;
	cursor: default;
	color: inherit;
	.root &,
	.root &:hover {
		text-decoration: none;
		color: inherit;
	}
}
.sp_click,
.pc_no_click {
	pointer-events: none;
	cursor: default;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************



## Component - Base

コンポーネント設定。
各ページでよく使い回す記述。
このサイト専用の共通設定。

*/

/**************************

## - 段組設定 2列 3列 4列

PCの時だけ、中央に等間隔で余白を作り、width: 100%; を守って列になる。
ul でなくてもいい。

**2列 .pc_column2**

	<ul class="pc_column2">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**3列 .pc_column3**

	<ul class="pc_column3">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**4列 .pc_column4**

	<ul class="pc_column4">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

*/

.pc_column2 {
	@include columns(2, 20px);
}
.pc_column3 {
	@include columns(3, 20px);
}
.pc_column4 {
	@include columns(4, 20px);
}

.pc_column2b {
	@include columnsb(2, 30px);
}
.pc_column3b {
	@include columnsb(3, 20px);
}
.pc_column4b {
	@include columnsb(4, 20px);
}

.pc_column2,
.pc_column3,
.pc_column4 {
	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
		vertical-align: bottom;
	}
}

.set {
	display: flex;
	justify-content: space-between;

	width: 100%;
	max-width: calc(100% - 30px);

	> * {
		flex-grow: 5;
	}

	> .left {
		order: 1;
	}
	> .right {
		order: 2;
	}
	> .fix {
		flex-shrink: 0;
		flex-grow: 0;
		width: 100%;
	}
	> .flex {
		display: flex;
	}
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Project

特定ページ専用、効果範囲が限定的な記述。
コンテンツ内容に密接で、汎用性がなく、機能の付け替えを想定していない。

*/

.header {
	height: $pc_header_height;
	// backdrop-filter: blur(5px) brightness(105%);
	backdrop-filter: blur(10px) brightness(107%);
	background-color: rgba(255, 255, 255, 0.7);
	transition: background-color 0.2s $easeOutQuint;

	@supports not (backdrop-filter: blur(30px)) {
		// background: rgba(255, 255, 255, .8);
		background-color: red;
	}

	&._show_sub_menu {
		background-color: #fff;
	}
	/* &:hover {
		background-color: #fff;
	} */

	&__inner {
		display: flex;
	}
	&__l {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-basis: 330px;
		flex-shrink: 0;
		flex-grow: 0;
		border-right: 1px solid #7da1cd;
		@media #{$lg} {
			flex-basis: 240px;
		}
	}
	&__r {
		flex-grow: 10;
		display: flex;
		flex-direction: column;

		&__1 {
			height: 39px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid palette("border", "default");
			&__l {
				display: flex;
			}
			&__r {
				display: flex;
				position: relative;
			}
		}
		&__2 {
			height: 60px;
		}
	}

	&__logo {
		// padding: 24px 33px 22px 24px;
		width: 280px;
		@media #{$lg} {
			width: 220px;
		}
	}
	&__info {
		display: flex;
		justify-content: space-between;
		//border-bottom: 1px solid #7fa2cf;
		position: relative;
		&::before {
			display: block;
			content: "";
			width: 100%;
			height: 1px;
			background: linear-gradient(to right, #7fa2cf 0%, #7fa2cf 99%, #fff 100%);
			position: absolute;
			bottom: -1px;
			left: 0;
			@media (max-width: 1599px) {
				background: #7fa2cf;
			}
		}
	}
	&__step_link {
		display: flex;
		overflow: hidden;
		> li {
			position: relative;
			> a {
				// @include on_opacity;
				display: block;
				color: #2a3038;
				font-size: $fontsize15;
				padding: 7px 15px 7px 30px;

				transition: color 0.2s $easeOutQuint;

				&:hover {
					color: palette("link", "default");
				}

				&::before {
					display: block;
					content: "";
					position: absolute;
					pointer-events: none;
					margin: auto;
					top: 50%;
					transform: translateY(-50%);
				}

				&._faq {
					padding-left: 33px;
					&::before {
						width: 17px;
						height: 17px;
						background: url("../img/icon/faq.webp") center center / contain no-repeat transparent;
						left: 15px;
					}
				}
				&._faqicon {
					padding-left: 33px;
					&::before {
						width: 14px;
						height: 12px;
						background: url("../img/icon/faqicon.webp") center center / contain no-repeat transparent;
						left: 12px;
					}
				}
				&._contact {
					padding-left: 33px;
					&::before {
						width: 18px;
						height: 14px;
						background: url("../img/icon/contact.webp") center center / contain no-repeat transparent;
						left: 12px;
						margin-top: 1px;
					}
				}
				&._access {
					padding-left: 33px;
					&::before {
						width: 11px;
						height: 15px;
						background: url("../img/icon/access.webp") center center / contain no-repeat transparent;
						left: 17px;
					}
				}

				// &._initial {
				// 	padding-left: 33px;
				// 	&::before {
				// 		width: 11px;
				// 		height: 15px;
				// 		background: url('../img/icon/initial.webp') center center / contain no-repeat transparent;
				// 		left: 17px;
				// 	}
				// }
				// &._repeated {
				// 	padding-left: 33px;
				// 	padding-right: 12px;

				// 	&::before {
				// 		width: 17px;
				// 		height: 17px;
				// 		background: url('../img/icon/repeated.webp') center center / contain no-repeat transparent;
				// 		left: 12px;
				// 	}
				// }
				// &._hospitalization {
				// 	padding-left: 34px;
				// 	padding-right: 17px;

				// 	&::before {
				// 		width: 15px;
				// 		height: 16px;
				// 		background: url('../img/icon/hospitalization.webp') center center / contain no-repeat transparent;
				// 		left: 16px;
				// 	}
				// }
				// &._discharge {
				// 	padding-left: 35px;

				// 	&::before {
				// 		width: 15px;
				// 		height: 15px;
				// 		background: url('../img/icon/discharge.webp') center center / contain no-repeat transparent;
				// 		left: 17px;
				// 	}
				// }
			}

			// &:not(:last-of-type) {
			// 	position: relative;
			// 	&:after {
			// 		display: block;
			// 		content: '';
			// 		width: 7px;
			// 		height: 39px;
			// 		background: url('../img/header/step_arrow.webp') no-repeat;
			// 		background-size: contain;
			// 		position: absolute;
			// 		top: 0;
			// 		right: -4px;
			// 		bottom: 0;
			// 		left: auto;
			// 		margin: auto;
			// 	}
			// }
		}
	}

	&__action_list {
		display: flex;
		> li {
			display: flex;
			// margin-right: 24px;
			padding-left: 20px;
			padding-right: 5px;
			position: relative;
			> span {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding-right: 17px;
				line-height: 1;
				color: #2a3038;
				font-size: $fontsize14;
				position: relative;
				&:after {
					display: block;
					content: "";
					width: 12px;
					height: 8px;
					background: url("../img/icon/arrow/down_black.webp");
					background-size: contain;
					position: absolute;
					right: 0;
					margin-top: 3px;
				}
			}
			&._search {
				margin-left: 5px;
				> span {
					padding-right: 22px;
					&:after {
						display: block;
						content: "";
						width: 16px;
						height: 16px;
						background: url("../img/icon/search.webp");
						background-size: contain;
						margin-top: 1px;
					}
				}
				&.hidden {
					> span {
						&::after {
							display: none;
						}
					}
				}
			}
			&:hover {
				box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
				.action_list_detail {
					display: block;
				}
			}
			.action_list_detail {
				display: none;
				position: absolute;
				top: 100%;
				right: 0;
				bottom: auto;
				left: auto;
				margin: auto;
				background-color: #fff;
				// box-shadow: 0 0 5px rgba(0,0,0,0.5);
				// box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
				box-shadow: 10px 0px 10px -10px rgba(0, 0, 0, 0.3), -10px 0px 10px -10px rgba(0, 0, 0, 0.3), 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
				min-width: 120px;
				z-index: 20000;
				padding: 10px;
				box-sizing: border-box;
				> li {
					text-align: center;
					&:not(:last-of-type) {
						border-bottom: 1px solid #949fac;
					}
					> a {
						cursor: pointer;
						display: block;
						padding: 5px;
						color: #2a3038;
					}
					&._selected {
						background-color: #eff6ff !important;
					}
				}
				&._select_font_size {
					> li {
						> a {
							font-size: $fontsize18;
						}
						&._selected {
							> a {
								background-color: #eff6ff !important;
								color: #2a3038 !important;
							}
						}
					}
				}
				&._language {
					min-width: 130px;
					> li {
						position: relative;
						padding-right: 25px;
						&:after {
							display: block;
							content: "";
							width: 6px;
							height: 9px;
							background: url("../img/icon/arrow/link2.webp") no-repeat;
							background-size: contain;
							position: absolute;
							top: 0;
							right: 5px;
							bottom: 0;
							left: auto;
							margin: auto;
						}
					}
				}
				&._theme_color {
					> li {
						padding: 10px;
						> a {
							padding: 0;
							line-height: 1.3;
							font-size: $fontsize16;
							&._white {
								background-color: #fff;
							}
							&._black {
								background-color: #404c5b;
								color: #fff;
							}
						}
					}
				}
			}
		}
		@media #{$lg} {
			> li {
				margin-right: 14px;
				padding-left: 10px;
			}
		}
	}
	&__1 {
		width: 100%;
	}
}

.gnav {
	$height: 60px;
	$count: 7;
	&__ul {
		height: $height;
		display: flex;
		justify-content: center;
		align-items: center;

		> * {
			min-width: 0;
			max-width: 100%;
			// width: 100%;
		}
	}
	&__li {
		flex-grow: 10;
		display: flex;
		position: relative;
		top: 0;
		left: 0;
		// width: floor-decimal((100% /  $count), 2);
		&:not(:last-child) {
			&::after {
				content: "";
				display: block;
				width: 1px;
				height: 25px;
				position: absolute;
				top: 52%;
				right: 0;
				background: #96b1d4;
				transform: translateY(-50%);
			}
		}
	}
	&__a {
		@include tsukugo_m;
		display: flex;
		height: $height;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #102b50;
		line-height: 1.35;
		letter-spacing: 0.05em;
		width: 100%;
		text-align: center;
		font-weight: $fontsize17;
		position: relative;
		top: 0;
		left: 0;
		z-index: 2;
		@media screen and (min-width: 1200px), print {
			font-size: 18px;
		}
		@media screen and (max-width: 1199px) {
			font-size: 16px;
		}

		&._hover,
		&:hover {
			// background-color: palette('blue', 'bg7');
			background-color: palette("blue", "color2");
			color: #fff;
			// margin-left: -1px;
			// margin-right: -1px;
			&::before {
				content: "";
				display: block;
				width: 1px;
				height: 100%;
				position: absolute;
				top: 0;
				left: -1px;
				background-color: palette("blue", "text");
			}
		}
	}
}

.gnav_sub_area {
	position: absolute;
	top: calc(100% - 1px);
	left: 0;
	z-index: 1;
	width: 100%;
	height: 0;
	overflow: hidden;
	background: linear-gradient(to bottom, #fff 0%, #eef6fb 100%);
	box-sizing: border-box;

	// padding-left: 40px;
	// padding-right: 40px;

	// background-color: palette('blue', '1');
	// box-shadow: 0 5px 5px 0px rgba(#000, .1);

	.root .header & {
		width: 100%;
	}
	&._show {
		height: auto;
		display: block;
		animation-name: fade-in;
		animation-duration: 0.3s;
		animation-timing-function: $easeOutCubic;
		animation-fill-mode: forwards;
		border-top: 1px solid #6590c4;
		border-bottom: 1px solid #6590c4;
	}
	&._leave {
		height: auto;
		display: block;
		animation-name: fade-out;
		animation-duration: 0.2s;
		animation-timing-function: $easeOutCubic;
		animation-fill-mode: forwards;
	}
}

.gnav_sub {
	// width: 100vw;
	// min-height: 150px;
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;

	&__l {
		// background-color: rgba(red, .2);
		width: 30%;
		display: flex;
		align-items: center;

		.title {
			padding: 10px;
			a {
				display: inline-block;

				transition: background-color 0.2s $easeOutQuint;
				border-radius: 0.2em;
				&:hover {
					background-color: palette("link", "hover_bg");
				}
			}
			.ja {
				@include midashi;
				font-size: $fontsize20;
				color: palette("blue", "color");
				line-height: 1.3;
				margin-bottom: 0.25em;
				&::after {
					@include bg-inline("../img/icon/arrow/link.webp", 22px, 22px);
					display: inline-block;
					line-height: 1;
					margin-left: 0.4em;
					position: relative;
					top: 0.15em;
					left: 0;
				}
			}
			.en {
				@include source_serif;
				font-size: 15px;
				color: palette("blue", "color2");
				padding-left: 0.2em;
				line-height: 1.3;
			}
		}
	}
	&__r {
		width: 100%;
		// background-color: rgba(blue, .2);
		padding: 30px 20px;
		box-sizing: border-box;
	}
	&__list {
		font-size: 0;
		// width: 100%;
		column-count: 3;
		// column-fit: auto;
		margin-left: -15px;
		margin-right: -15px;

		&._column {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
		}

		.li1 {
			// margin-bottom: 12px;
			padding-left: 10px;
			padding-right: 10px;
			// width: 33.33%;

			&-attempt {
				display: none;
			}
		}
		.a1 {
			font-size: $fontsize16;
			display: block;
			// display: inline-block;
			border-radius: 0.2em;
			color: palette("blue", "color");
			padding-top: 0.37em;
			padding-bottom: 0.37em;
			padding-left: 22px + 5px;
			padding-right: 5px;
			position: relative;
			top: 0;
			left: 0;
			line-height: 1.3;
			letter-spacing: 0;
			transition: background-color 0.2s $easeOutQuint;

			&::before {
				@include bg-block("../img/icon/arrow/link_s.webp", 16px, 16px);
				position: absolute;
				top: 0.5em;
				left: 5px;
				transition: left 0.2s $easeOutQuint;
			}
			&:hover {
				background-color: palette("link", "hover_bg");
			}
			.gnav_block {
				display: block;
			}
		}

		.js-accordion_toggle,
		.sub_menu__ul2 {
			display: none;
		}

		&._dock {
			> .li1 {
				width: 100%;
				&:not(:last-child) {
					margin-bottom: 25px;
				}
			}
			.sub_menu__ul2 {
				// display: block;
				padding-left: 7px;

				display: flex;
				flex-wrap: wrap;
				padding-top: 5px;

				.li2 {
					margin-top: 3px;
					min-width: 25%;
					box-sizing: border-box;
					padding-right: 20px;
				}

				.a2 {
					font-size: $fontsize15;
					color: #fff;

					position: relative;
					top: 0;
					left: 0;
					padding-left: 26px;

					&::before {
						@include bg-inline("../img/icon/arrow/link3.webp", 18px, 18px);
						margin-right: 6px;
						position: absolute;
						top: -1px;
						left: 0;
						transition: left 0.1s ease-in-out;
					}
					&:hover {
						text-decoration: underline;
						&::before {
							left: 3px;
						}
					}
				}
			}
			.js-accordion_content {
				opacity: 1;
				max-height: none;
			}
		}
	}
	&__bottom_list_area {
		$padding: 5px;
		background-color: #fff;
		margin-top: 2em;

		position: relative;
		top: 0;
		left: 0;
		padding-top: 30px;
		padding-left: 40px;
		padding-right: $padding;
		padding-bottom: 18px;

		margin-left: -$padding;
		margin-right: -$padding;
		// padding-left: $padding;

		.a1 {
			position: absolute;
			top: -0.7em;
			left: $padding + 10px;
		}
	}
	&__list-bottom {
		.li1-attempt {
			display: block;
		}
		.sub_menu__ul2 {
			display: block;
		}
		.li2 {
			margin-bottom: 10px;
		}
		.a2 {
			display: block;
			font-size: 1rem;
			color: palette("blue", "color");
			padding-left: 20px;
			position: relative;
			top: 0;
			left: 0;
			line-height: 1.3;
			border-radius: 0.2em;
			transition: background-color 0.2s $easeOutQuint;
			&::before {
				@include bg-block("../img/icon/arrow/link2.webp", 6px, 9px);
				position: absolute;
				top: 0.45em;
				left: 5px;
			}
			&:hover {
				background-color: palette("link", "hover_bg");
			}
		}
	}
	&__content_list_area {
		padding-left: 10px;
		padding-top: 20px;
	}

	&__top_list_area {
		padding-left: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid palette("blue", "color2");
	}
}

.gnav_sub_thumb_list {
	@include columns(3, 30px);

	a {
		display: block;
		max-width: 270px;
		margin: auto;
		padding-bottom: 10px;
		transition: background-color 0.2s $easeOutQuint;
		.image {
			margin-bottom: 10px;
			transition: transform 0.5s $easeOutBack;
			transform-origin: bottom center;
			img {
				display: block;
			}
		}
		.title {
			position: relative;
			top: 0;
			left: 0;
			line-height: 1.3;
			padding-left: 0.7em;
			color: palette("blue", "color");
			transition: transform 0.2s $easeOutQuint;
			&::before {
				content: "";
				display: block;
				background: linear-gradient(to bottom, #0046a0 0%, #73d5ed 100%);
				width: 2px;
				height: 100%;
				position: absolute;
				top: 0.1em;
				left: 0;
			}
		}

		&:hover {
			background-color: palette("link", "hover_bg");
			.image {
				transform: scale(0.96);
			}
			.title {
				transform: translateX(6px);
			}
		}
	}
}
