@charset "UTF-8";
/**************************
Compass Text Replacement
*/
/**************************
Inline List
*/
/**************************
Inline Block List
*/
/**************************
horizontal-list
*/
/**************************
Bullets
*/
/* simple clearfix */
/**************************
@mixin for sprite
*/
@keyframes fade-in {
	0% {
		display: none;
		opacity: 0;
	}
	1% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}
@keyframes fade-out {
	0% {
		display: block;
		opacity: 1;
	}
	99% {
		display: block;
		opacity: 0;
	}
	100% {
		display: none;
		opacity: 0;
	}
}
@keyframes _on_opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes header_index_show {
	0% {
		display: none;
	}
	1% {
		display: block;
		opacity: 0;
		top: -30px;
	}
	100% {
		top: 0;
		opacity: 1;
		display: block;
	}
}
@keyframes header_index_hide {
	0% {
		top: 0;
		opacity: 1;
		display: block;
	}
	99% {
		top: -30px;
		opacity: 0;
		display: block;
	}
	100% {
		top: -30px;
		opacity: 0;
		display: none;
	}
}
@keyframes header_search_show {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(64px);
	}
}
@keyframes header_search_hide {
	0% {
		transform: translateY(64px);
	}
	100% {
		transform: translateY(0);
	}
}
/***********************************************************************
 ***********************************************************************
 ***********************************************************************
## Layout
*/
.root {
	box-sizing: border-box;
	overflow: hidden;
	position: relative;
	top: 0;
	left: 0;
}

.pc_container,
.ipc_container,
.pc_tb_container,
.container {
	width: 100%;
	box-sizing: border-box;
	padding-left: 30px;
	padding-right: 30px;
	margin-left: auto;
	margin-right: auto;
	max-width: 1260px;
}
.pc_container._relative,
.ipc_container._relative,
.pc_tb_container._relative,
.container._relative {
	position: relative;
	top: 0;
	left: 0;
}
.pc_container._md,
.ipc_container._md,
.pc_tb_container._md,
.container._md {
	width: 100%;
	box-sizing: border-box;
	padding-left: 30px;
	padding-right: 30px;
	margin-left: auto;
	margin-right: auto;
	max-width: 1060px;
}
.pc_container._sm,
.ipc_container._sm,
.pc_tb_container._sm,
.container._sm {
	width: 100%;
	box-sizing: border-box;
	padding-left: 30px;
	padding-right: 30px;
	margin-left: auto;
	margin-right: auto;
	max-width: 600px;
}
.pc_container._sm2,
.ipc_container._sm2,
.pc_tb_container._sm2,
.container._sm2 {
	width: 100%;
	box-sizing: border-box;
	padding-left: 30px;
	padding-right: 30px;
	margin-left: auto;
	margin-right: auto;
	max-width: 300px;
}

.pc_padding,
.padding {
	padding-left: 30px;
	padding-right: 30px;
}

.main_flex_area {
	position: relative;
	top: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
}
.main_flex_area > * {
	min-width: 0;
	max-width: 100%;
	width: 100%;
}

.main_column {
	order: 1;
	width: 100%;
	box-sizing: border-box;
	padding-left: 40px;
}

.sub_column {
	order: 2;
	width: 215px;
	flex-basis: 215px;
	flex-shrink: 0;
	flex-grow: 0;
	overflow: hidden;
	box-sizing: content-box;
	transform: translateX(-1px);
	padding-left: 11px;
	padding-right: 11px;
	border-right: 1px solid #b1b5ba;
	padding-top: 45px;
}

.main_content {
	font-size: 1rem;
	margin-bottom: 120px;
}

.scroll_to_top {
	right: 0;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Utility - Cosmetic

ユーティリティー設定。
このサイト専用というわけでもない共通設定。

*/
.pc_none,
.root .pc_none {
	display: none;
}

.block,
.pc_block,
.ipc_block {
	display: block;
}

.xs_block {
	display: none;
}

@media screen and (min-width: 1200px), print {
	.lg_block {
		display: block;
	}
}
@media screen and (min-width: 1000px) and (max-width: 1099px), print {
	.pc_s_block {
		display: block;
	}
}
.tel_link,
.tel_link-no {
	pointer-events: none;
	cursor: default;
	color: inherit;
}
.root .tel_link, .root .tel_link:hover,
.root .tel_link-no,
.root .tel_link-no:hover {
	text-decoration: none;
	color: inherit;
}

.sp_click,
.pc_no_click {
	pointer-events: none;
	cursor: default;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************



## Component - Base

コンポーネント設定。
各ページでよく使い回す記述。
このサイト専用の共通設定。

*/
/**************************

## - 段組設定 2列 3列 4列

PCの時だけ、中央に等間隔で余白を作り、width: 100%; を守って列になる。
ul でなくてもいい。

**2列 .pc_column2**

	<ul class="pc_column2">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**3列 .pc_column3**

	<ul class="pc_column3">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**4列 .pc_column4**

	<ul class="pc_column4">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

*/
.pc_column2 {
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
	margin-bottom: -20px;
	width: calc(100% + 20px);
}
.pc_column2 > * {
	padding-left: 10px;
	padding-right: 10px;
	box-sizing: border-box;
	width: 50%;
	margin-bottom: 20px;
}

.pc_column3 {
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
	margin-bottom: -20px;
	width: calc(100% + 20px);
}
.pc_column3 > * {
	padding-left: 10px;
	padding-right: 10px;
	box-sizing: border-box;
	width: 33.33%;
	margin-bottom: 20px;
}

.pc_column4 {
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
	margin-bottom: -20px;
	width: calc(100% + 20px);
}
.pc_column4 > * {
	padding-left: 10px;
	padding-right: 10px;
	box-sizing: border-box;
	width: 25%;
	margin-bottom: 20px;
}

.pc_column2b::before, .pc_column2b::after {
	content: "";
	display: table;
}
.pc_column2b::after {
	clear: both;
}
.pc_column2b > * {
	width: 50%;
	float: left;
	box-sizing: border-box;
}
.pc_column2b > *:nth-child(2n+1) {
	clear: left;
	padding-right: 15px;
}
.pc_column2b > *:nth-child(2n) {
	padding-left: 15px;
}

.pc_column3b::before, .pc_column3b::after {
	content: "";
	display: table;
}
.pc_column3b::after {
	clear: both;
}
.pc_column3b > * {
	width: 33.3333333333%;
	float: left;
	box-sizing: border-box;
}
.pc_column3b > *:nth-child(3n+1) {
	clear: left;
	padding-right: 13px;
}
.pc_column3b > *:nth-child(3n+2) {
	padding-left: 6px;
	padding-right: 6px;
}
.pc_column3b > *:nth-child(3n) {
	padding-left: 13px;
}

.pc_column4b::before, .pc_column4b::after {
	content: "";
	display: table;
}
.pc_column4b::after {
	clear: both;
}
.pc_column4b > * {
	width: 25%;
	float: left;
	box-sizing: border-box;
}
.pc_column4b > *:nth-child(4n+1) {
	clear: left;
	padding-right: 15px;
}
.pc_column4b > *:nth-child(4n+2) {
	padding-left: 5px;
	padding-right: 10px;
}
.pc_column4b > *:nth-child(4n+3) {
	padding-left: 10px;
	padding-right: 5px;
}
.pc_column4b > *:nth-child(4n) {
	padding-left: 15px;
}

.pc_column2 img,
.pc_column3 img,
.pc_column4 img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	vertical-align: bottom;
}

.set {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: calc(100% - 30px);
}
.set > * {
	flex-grow: 5;
}
.set > .left {
	order: 1;
}
.set > .right {
	order: 2;
}
.set > .fix {
	flex-shrink: 0;
	flex-grow: 0;
	width: 100%;
}
.set > .flex {
	display: flex;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Project

特定ページ専用、効果範囲が限定的な記述。
コンテンツ内容に密接で、汎用性がなく、機能の付け替えを想定していない。

*/
.header {
	height: 100px;
	backdrop-filter: blur(10px) brightness(107%);
	background-color: rgba(255, 255, 255, 0.7);
	transition: background-color 0.2s cubic-bezier(0.23, 1, 0.32, 1);
	/* &:hover {
		background-color: #fff;
	} */
}
@supports not (backdrop-filter: blur(30px)) {
	.header {
		background-color: red;
	}
}
.header._show_sub_menu {
	background-color: #fff;
}
.header__inner {
	display: flex;
}
.header__l {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-basis: 330px;
	flex-shrink: 0;
	flex-grow: 0;
	border-right: 1px solid #7da1cd;
}
@media screen and (max-width: 1199px) {
	.header__l {
		flex-basis: 240px;
	}
}
.header__r {
	flex-grow: 10;
	display: flex;
	flex-direction: column;
}
.header__r__1 {
	height: 39px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #7da1cd;
}
.header__r__1__l {
	display: flex;
}
.header__r__1__r {
	display: flex;
	position: relative;
}
.header__r__2 {
	height: 60px;
}
.header__logo {
	width: 280px;
}
@media screen and (max-width: 1199px) {
	.header__logo {
		width: 220px;
	}
}
.header__info {
	display: flex;
	justify-content: space-between;
	position: relative;
}
.header__info::before {
	display: block;
	content: "";
	width: 100%;
	height: 1px;
	background: linear-gradient(to right, #7fa2cf 0%, #7fa2cf 99%, #fff 100%);
	position: absolute;
	bottom: -1px;
	left: 0;
}
@media (max-width: 1599px) {
	.header__info::before {
		background: #7fa2cf;
	}
}
.header__step_link {
	display: flex;
	overflow: hidden;
}
.header__step_link > li {
	position: relative;
}
.header__step_link > li > a {
	display: block;
	color: #2a3038;
	font-size: 0.938rem;
	padding: 7px 15px 7px 30px;
	transition: color 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.header__step_link > li > a:hover {
	color: #002e7d;
}
.header__step_link > li > a::before {
	display: block;
	content: "";
	position: absolute;
	pointer-events: none;
	margin: auto;
	top: 50%;
	transform: translateY(-50%);
}
.header__step_link > li > a._faq {
	padding-left: 33px;
}
.header__step_link > li > a._faq::before {
	width: 17px;
	height: 17px;
	background: url("../img/icon/faq.webp") center center/contain no-repeat transparent;
	left: 15px;
}
.header__step_link > li > a._faqicon {
	padding-left: 33px;
}
.header__step_link > li > a._faqicon::before {
	width: 14px;
	height: 12px;
	background: url("../img/icon/faqicon.webp") center center/contain no-repeat transparent;
	left: 12px;
}
.header__step_link > li > a._contact {
	padding-left: 33px;
}
.header__step_link > li > a._contact::before {
	width: 18px;
	height: 14px;
	background: url("../img/icon/contact.webp") center center/contain no-repeat transparent;
	left: 12px;
	margin-top: 1px;
}
.header__step_link > li > a._access {
	padding-left: 33px;
}
.header__step_link > li > a._access::before {
	width: 11px;
	height: 15px;
	background: url("../img/icon/access.webp") center center/contain no-repeat transparent;
	left: 17px;
}
.header__action_list {
	display: flex;
}
.header__action_list > li {
	display: flex;
	padding-left: 20px;
	padding-right: 5px;
	position: relative;
}
.header__action_list > li > span {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-right: 17px;
	line-height: 1;
	color: #2a3038;
	font-size: 0.875rem;
	position: relative;
}
.header__action_list > li > span:after {
	display: block;
	content: "";
	width: 12px;
	height: 8px;
	background: url("../img/icon/arrow/down_black.webp");
	background-size: contain;
	position: absolute;
	right: 0;
	margin-top: 3px;
}
.header__action_list > li._search {
	margin-left: 5px;
}
.header__action_list > li._search > span {
	padding-right: 22px;
}
.header__action_list > li._search > span:after {
	display: block;
	content: "";
	width: 16px;
	height: 16px;
	background: url("../img/icon/search.webp");
	background-size: contain;
	margin-top: 1px;
}
.header__action_list > li._search.hidden > span::after {
	display: none;
}
.header__action_list > li:hover {
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.header__action_list > li:hover .action_list_detail {
	display: block;
}
.header__action_list > li .action_list_detail {
	display: none;
	position: absolute;
	top: 100%;
	right: 0;
	bottom: auto;
	left: auto;
	margin: auto;
	background-color: #fff;
	box-shadow: 10px 0px 10px -10px rgba(0, 0, 0, 0.3), -10px 0px 10px -10px rgba(0, 0, 0, 0.3), 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
	min-width: 120px;
	z-index: 20000;
	padding: 10px;
	box-sizing: border-box;
}
.header__action_list > li .action_list_detail > li {
	text-align: center;
}
.header__action_list > li .action_list_detail > li:not(:last-of-type) {
	border-bottom: 1px solid #949fac;
}
.header__action_list > li .action_list_detail > li > a {
	cursor: pointer;
	display: block;
	padding: 5px;
	color: #2a3038;
}
.header__action_list > li .action_list_detail > li._selected {
	background-color: #eff6ff !important;
}
.header__action_list > li .action_list_detail._select_font_size > li > a {
	font-size: 1.125rem;
}
.header__action_list > li .action_list_detail._select_font_size > li._selected > a {
	background-color: #eff6ff !important;
	color: #2a3038 !important;
}
.header__action_list > li .action_list_detail._language {
	min-width: 130px;
}
.header__action_list > li .action_list_detail._language > li {
	position: relative;
	padding-right: 25px;
}
.header__action_list > li .action_list_detail._language > li:after {
	display: block;
	content: "";
	width: 6px;
	height: 9px;
	background: url("../img/icon/arrow/link2.webp") no-repeat;
	background-size: contain;
	position: absolute;
	top: 0;
	right: 5px;
	bottom: 0;
	left: auto;
	margin: auto;
}
.header__action_list > li .action_list_detail._theme_color > li {
	padding: 10px;
}
.header__action_list > li .action_list_detail._theme_color > li > a {
	padding: 0;
	line-height: 1.3;
	font-size: 1rem;
}
.header__action_list > li .action_list_detail._theme_color > li > a._white {
	background-color: #fff;
}
.header__action_list > li .action_list_detail._theme_color > li > a._black {
	background-color: #404c5b;
	color: #fff;
}
@media screen and (max-width: 1199px) {
	.header__action_list > li {
		margin-right: 14px;
		padding-left: 10px;
	}
}
.header__1 {
	width: 100%;
}

.gnav__ul {
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.gnav__ul > * {
	min-width: 0;
	max-width: 100%;
}
.gnav__li {
	flex-grow: 10;
	display: flex;
	position: relative;
	top: 0;
	left: 0;
}
.gnav__li:not(:last-child)::after {
	content: "";
	display: block;
	width: 1px;
	height: 25px;
	position: absolute;
	top: 52%;
	right: 0;
	background: #96b1d4;
	transform: translateY(-50%);
}
.gnav__a {
	font-family: "FOT-筑紫ゴシック Pro M", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	display: flex;
	height: 60px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #102b50;
	line-height: 1.35;
	letter-spacing: 0.05em;
	width: 100%;
	text-align: center;
	font-weight: 1.063rem;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
}
@media screen and (min-width: 1200px), print {
	.gnav__a {
		font-size: 18px;
	}
}
@media screen and (max-width: 1199px) {
	.gnav__a {
		font-size: 16px;
	}
}
.gnav__a._hover, .gnav__a:hover {
	background-color: #095b91;
	color: #fff;
}
.gnav__a._hover::before, .gnav__a:hover::before {
	content: "";
	display: block;
	width: 1px;
	height: 100%;
	position: absolute;
	top: 0;
	left: -1px;
	background-color: #2a6ba7;
}

.gnav_sub_area {
	position: absolute;
	top: calc(100% - 1px);
	left: 0;
	z-index: 1;
	width: 100%;
	height: 0;
	overflow: hidden;
	background: linear-gradient(to bottom, #fff 0%, #eef6fb 100%);
	box-sizing: border-box;
}
.root .header .gnav_sub_area {
	width: 100%;
}
.gnav_sub_area._show {
	height: auto;
	display: block;
	animation-name: fade-in;
	animation-duration: 0.3s;
	animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	animation-fill-mode: forwards;
	border-top: 1px solid #6590c4;
	border-bottom: 1px solid #6590c4;
}
.gnav_sub_area._leave {
	height: auto;
	display: block;
	animation-name: fade-out;
	animation-duration: 0.2s;
	animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	animation-fill-mode: forwards;
}

.gnav_sub {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
}
.gnav_sub__l {
	width: 30%;
	display: flex;
	align-items: center;
}
.gnav_sub__l .title {
	padding: 10px;
}
.gnav_sub__l .title a {
	display: inline-block;
	transition: background-color 0.2s cubic-bezier(0.23, 1, 0.32, 1);
	border-radius: 0.2em;
}
.gnav_sub__l .title a:hover {
	background-color: #dce3ed;
}
.gnav_sub__l .title .ja {
	font-family: "FOT-筑紫ゴシック Pro B", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-size: 1.25rem;
	color: #102b50;
	line-height: 1.3;
	margin-bottom: 0.25em;
}
.gnav_sub__l .title .ja::after {
	content: "";
	display: inline-block;
	background: url("../img/icon/arrow/link.webp") 0 center no-repeat transparent;
	background-size: 22px 22px;
	width: 22px;
	height: 22px;
	display: inline-block;
	line-height: 1;
	margin-left: 0.4em;
	position: relative;
	top: 0.15em;
	left: 0;
}
.gnav_sub__l .title .en {
	font-family: "Source Serif Pro", sans-serif;
	font-weight: normal;
	font-size: 15px;
	color: #095b91;
	padding-left: 0.2em;
	line-height: 1.3;
}
.gnav_sub__r {
	width: 100%;
	padding: 30px 20px;
	box-sizing: border-box;
}
.gnav_sub__list {
	font-size: 0;
	column-count: 3;
	margin-left: -15px;
	margin-right: -15px;
}
.gnav_sub__list._column {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}
.gnav_sub__list .li1 {
	padding-left: 10px;
	padding-right: 10px;
}
.gnav_sub__list .li1-attempt {
	display: none;
}
.gnav_sub__list .a1 {
	font-size: 1rem;
	display: block;
	border-radius: 0.2em;
	color: #102b50;
	padding-top: 0.37em;
	padding-bottom: 0.37em;
	padding-left: 27px;
	padding-right: 5px;
	position: relative;
	top: 0;
	left: 0;
	line-height: 1.3;
	letter-spacing: 0;
	transition: background-color 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.gnav_sub__list .a1::before {
	content: "";
	display: block;
	background: url("../img/icon/arrow/link_s.webp") 0 0 no-repeat transparent;
	background-size: 16px 16px;
	width: 16px;
	height: 16px;
	position: absolute;
	top: 0.5em;
	left: 5px;
	transition: left 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.gnav_sub__list .a1:hover {
	background-color: #dce3ed;
}
.gnav_sub__list .a1 .gnav_block {
	display: block;
}
.gnav_sub__list .js-accordion_toggle,
.gnav_sub__list .sub_menu__ul2 {
	display: none;
}
.gnav_sub__list._dock > .li1 {
	width: 100%;
}
.gnav_sub__list._dock > .li1:not(:last-child) {
	margin-bottom: 25px;
}
.gnav_sub__list._dock .sub_menu__ul2 {
	padding-left: 7px;
	display: flex;
	flex-wrap: wrap;
	padding-top: 5px;
}
.gnav_sub__list._dock .sub_menu__ul2 .li2 {
	margin-top: 3px;
	min-width: 25%;
	box-sizing: border-box;
	padding-right: 20px;
}
.gnav_sub__list._dock .sub_menu__ul2 .a2 {
	font-size: 0.938rem;
	color: #fff;
	position: relative;
	top: 0;
	left: 0;
	padding-left: 26px;
}
.gnav_sub__list._dock .sub_menu__ul2 .a2::before {
	content: "";
	display: inline-block;
	background: url("../img/icon/arrow/link3.webp") 0 center no-repeat transparent;
	background-size: 18px 18px;
	width: 18px;
	height: 18px;
	margin-right: 6px;
	position: absolute;
	top: -1px;
	left: 0;
	transition: left 0.1s ease-in-out;
}
.gnav_sub__list._dock .sub_menu__ul2 .a2:hover {
	text-decoration: underline;
}
.gnav_sub__list._dock .sub_menu__ul2 .a2:hover::before {
	left: 3px;
}
.gnav_sub__list._dock .js-accordion_content {
	opacity: 1;
	max-height: none;
}
.gnav_sub__bottom_list_area {
	background-color: #fff;
	margin-top: 2em;
	position: relative;
	top: 0;
	left: 0;
	padding-top: 30px;
	padding-left: 40px;
	padding-right: 5px;
	padding-bottom: 18px;
	margin-left: -5px;
	margin-right: -5px;
}
.gnav_sub__bottom_list_area .a1 {
	position: absolute;
	top: -0.7em;
	left: 15px;
}
.gnav_sub__list-bottom .li1-attempt {
	display: block;
}
.gnav_sub__list-bottom .sub_menu__ul2 {
	display: block;
}
.gnav_sub__list-bottom .li2 {
	margin-bottom: 10px;
}
.gnav_sub__list-bottom .a2 {
	display: block;
	font-size: 1rem;
	color: #102b50;
	padding-left: 20px;
	position: relative;
	top: 0;
	left: 0;
	line-height: 1.3;
	border-radius: 0.2em;
	transition: background-color 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.gnav_sub__list-bottom .a2::before {
	content: "";
	display: block;
	background: url("../img/icon/arrow/link2.webp") 0 0 no-repeat transparent;
	background-size: 6px 9px;
	width: 6px;
	height: 9px;
	position: absolute;
	top: 0.45em;
	left: 5px;
}
.gnav_sub__list-bottom .a2:hover {
	background-color: #dce3ed;
}
.gnav_sub__content_list_area {
	padding-left: 10px;
	padding-top: 20px;
}
.gnav_sub__top_list_area {
	padding-left: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #095b91;
}

.gnav_sub_thumb_list {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: -30px;
	width: calc(100% + 30px);
}
.gnav_sub_thumb_list > * {
	padding-left: 15px;
	padding-right: 15px;
	box-sizing: border-box;
	width: 33.33%;
	margin-bottom: 30px;
}
.gnav_sub_thumb_list a {
	display: block;
	max-width: 270px;
	margin: auto;
	padding-bottom: 10px;
	transition: background-color 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.gnav_sub_thumb_list a .image {
	margin-bottom: 10px;
	transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transform-origin: bottom center;
}
.gnav_sub_thumb_list a .image img {
	display: block;
}
.gnav_sub_thumb_list a .title {
	position: relative;
	top: 0;
	left: 0;
	line-height: 1.3;
	padding-left: 0.7em;
	color: #102b50;
	transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.gnav_sub_thumb_list a .title::before {
	content: "";
	display: block;
	background: linear-gradient(to bottom, #0046a0 0%, #73d5ed 100%);
	width: 2px;
	height: 100%;
	position: absolute;
	top: 0.1em;
	left: 0;
}
.gnav_sub_thumb_list a:hover {
	background-color: #dce3ed;
}
.gnav_sub_thumb_list a:hover .image {
	transform: scale(0.96);
}
.gnav_sub_thumb_list a:hover .title {
	transform: translateX(6px);
}